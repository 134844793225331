import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import _ from 'underscore';
window._ = _;

class EvpForm  {
    constructor( $form ) {
        this.$form = $form;
        this.$cont = $form.closest('.evp-form-cont');
        this.form_id = $form.attr('id');
        this.url = $form.data('submit-url');
        this.action = $form.data('submit-action');

        console.log('Initialize evp-form', this.form_id);

        this.$submit = $form.find('button[type=submit]');

        const thisform = this;

        this.$submit.on('click', function ( e ) {

            e.preventDefault();
            // const data = {};
            thisform.setBusy(true);

            let thisFormData = new FormData();
            // let hasFiles = false;
            thisform.$form.find('.evp-form-control').each( function () {
                const $this = $(this);
                console.log("Found control", $this)
                if ($this.hasClass('checkbox-container')) {
                    const $cb = $this.find('input[type=checkbox]');
                    if ($cb.length) {
                        const cbname = $cb.attr('name');
                        if (cbname) {
                            thisFormData.append(cbname, $cb.prop('checked') ? '1' : '0');
                        }
                    }
                }
                else if ($this.prop('tagName') === 'INPUT') {
                    const name = $this.attr('name');
                    const type = $this.attr('type');
                    if (name) {
                        if (type && type === 'file') {
                            const thisFile = $this[0].files[0]
                            thisFormData.append(name, thisFile ? thisFile : '');
                        }
                        else if (type && type === 'checkbox')
                            thisFormData.append(name, $this.prop('checked') ? '1' : '0');
                        else
                            thisFormData.append(name, $this.val());
                    }
                }
                else if ($this.prop('tagName') === 'SELECT') {
                    const name = $this.attr('name');
                    thisFormData.append(name,  $this.val());
                }
                else if ($this.prop('tagName') === 'TEXTAREA') {
                    const txname = $this.attr('name');
                    if (txname)
                        thisFormData.append(txname, $this.val());
                }
            });

            thisFormData.append('__action',  thisform.action);

            console.log('submitting form '+thisform.form_id, thisFormData);

            $.ajax({
                url: thisform.url,
                method: 'POST',
                // dataType : 'json',
                data: thisFormData,
                enctype: 'multipart/form-data',
                processData: false, // tell jQuery not to process the data
                contentType: false, // tell jQuery not to set contentType

                success : function ( data ) {
                    console.log('Ajax response (success)', thisform.form_id, data, JSON.stringify(data));
                    if (data && data.result && data.result === 'OK') {
                        if (data['redirect'])
                            window.location = data['redirect'];
                        else if (data['reload'])
                            window.location.reload();
                        else if (data['set_on_success'] && _.isObject(data['set_on_success'])) {
                            _.forEach(data['set_on_success'], function (value, name) {
                                const $ctrl = $form.find('[name="' + name + '"]');
                                if ($ctrl.prop('type') === 'checkbox')
                                    $ctrl.prop('checked', value);
                                else
                                    $ctrl.val(value);
                            });
                        }
                        if (data.messages)
                            thisform.setMessage('success', data.messages);
                        thisform.setBusy(false);
                    }
                    else {
                        const messages = data && data.messages || [ 'Nieoczekiwany błąd [C01]. Wysłanie informacji nie powiodło się. Spróbuj ponownie później lub napisz do nas e-mail lub zadzwoń.' ];
                        thisform.setBusy(false);
                        thisform.setMessage('error', messages);
                    }
                },
                error : function (jqXHR, textStatus, errorThrown) {
                    thisform.setBusy(false);
                    console.error('Ajax Error, form', thisform.form_id, jqXHR, textStatus, errorThrown);
                    thisform.setMessage('error', 'Nieoczekiwany błąd [C02]. Wysłanie informacji nie powiodło się. Spróbuj ponownie później lub napisz do nas e-mail lub zadzwoń.')
                }
            });
        });

        this.$form.on('click', function () {
            thisform.removeFormMessage();

        });
    }

    setBusy ( isBusy ) {
        if (isBusy) {
            this.$form.addClass('evp-form-busy');
            this.$form.find('button[type="submit"]').addClass('button-loading');
        }
        else {
            this.$form.removeClass('evp-form-busy');
            this.$form.find('button[type="submit"]').removeClass('button-loading');
        }
    }

    setMessage ( type, messages  ) {
        if (!Array.isArray(messages))
            messages = [messages];
        console.log('Setting form '+type+' message ', messages);
        const $message = this.$cont.find('.evp-form-message');
        $message.addClass(type).slideDown( 100, function () {
            $message.html(messages.join('<br />'));
        });
    }

    removeFormMessage () {
        const $message = this.$cont.find('.evp-form-message');
        $message.removeClass('success').removeClass('error').slideUp( 100, function () {
            $message.html('');
        });
    }
}

export default EvpForm