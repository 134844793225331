import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import _ from 'underscore';
window._ = _;
import bootbox from 'bootbox';

class EvpAjaxCaller {

    /**
     * @param $clicker - clickable object infvoking action
     * @param $messenger - object to be used as message container (success or error)
     * @param $waiter - object to be set with evp-ajax-loading class/state (usually to show progress spin)
     * @param $blocker - object to be set with evp-ajax-busy class/state (usually to disable input)
     */
    constructor( $clicker, $messenger, $waiter, $blocker ) {
        this.$clicker = $clicker;
        this.$messenger = $messenger;
        this.$waiter = $waiter;
        this.$blocker = $blocker;
        this.url = '';
        this.req_data = undefined;
        this.confirm = '';

        const thiscaller = this;

        if (thiscaller.$clicker.length) {
            thiscaller.req_data = $clicker.data();
            if (!thiscaller.req_data['__action']) {
                if (thiscaller.req_data['action']) {
                    thiscaller.req_data['__action'] = thiscaller.req_data['action'];
                    delete thiscaller.req_data['action'];
                }
            }
            if (thiscaller.req_data['submit_url']) {
                thiscaller.url = thiscaller.req_data['submit_url'];
                delete thiscaller.req_data['submit_url'];
            }
            if (thiscaller.req_data['confirm']) {
                thiscaller.confirm = thiscaller.req_data['confirm'];
            }
        }

        if (thiscaller.$clicker.length && thiscaller.req_data && thiscaller.url) {
            this.$clicker.on('click', function ( e ) {
                e.preventDefault();

                function procede () {
                        thiscaller.setBusy(true);
                        console.log('Ajax call ', thiscaller.req_data);
                        $.ajax({
                            url: thiscaller.url,
                            method: 'POST',
                            dataType : 'json',
                            data: thiscaller.req_data,

                            success : function ( data ) {
                                console.log('Ajax response (success)', data, JSON.stringify(data));
                                if (data && data.result && data.result === 'OK') {
                                    if (data['redirect'])
                                        window.location = data['redirect'];
                                    else if (data['reload'])
                                        window.location.reload();
                                    if (data['messages'])
                                        thiscaller.setMessage('success', data['messages']);
                                    thiscaller.setBusy(false);
                                }
                                else {
                                    const messages = data && data['messages'] || [ 'Nieoczekiwany błąd [C01]. Akcja nie powiodła się. Spróbuj ponownie później lub napisz do nas e-mail lub zadzwoń.' ];
                                    thiscaller.setBusy(false);
                                    thiscaller.setMessage('error', messages);
                                }
                            },
                            error : function (jqXHR, textStatus, errorThrown) {
                                thiscaller.setBusy(false);
                                console.error('Ajax Error, form', jqXHR, textStatus, errorThrown);
                                thiscaller.setMessage('error', 'Nieoczekiwany błąd [C02]. Akcja nie powiodła się. Spróbuj ponownie później lub napisz do nas e-mail lub zadzwoń.')
                            }
                        });
                }
                if (thiscaller.confirm) {
                    bootbox.confirm(thiscaller.confirm, function(result) {
                        if (result) {
                            procede();
                        }
                    });
                }
                else {
                    procede();
                }
            });
        }

        /*

        $('body').on('click', function () {
            thiscaller.removeMessage();
        });

         */
    }

    setBusy ( isBusy ) {
        if (isBusy) {
            if (this.$waiter && this.$waiter.length)
                this.$waiter.addClass('evp-ajax-loading');
            if (this.$blocker && this.$blocker.length)
                this.$waiter.addClass('evp-ajax-busy');
        }
        else {
            if (this.$waiter && this.$waiter.length)
                this.$waiter.removeClass('evp-ajax-loading');
            if (this.$blocker && this.$blocker.length)
                this.$waiter.removeClass('evp-ajax-busy');
        }
    }

    setMessage ( type, messages  ) {
        if (this.$messenger && this.$messenger.length) {
            if (!Array.isArray(messages))
                messages = [messages];
            console.log('Setting ajax '+type+' message ', messages);
            this.$messenger.addClass(type).slideDown( 100, function () {
                this.$messenger.html(messages.join('<br />'));
            });
        }
    }

    removeMessage () {
        if (this.$messenger && this.$messenger.length) {
            this.$messenger.removeClass('success').removeClass('error').slideUp( 100, function () {
                this.$messenger.html('');
            });
        }
    }
}

export default EvpAjaxCaller