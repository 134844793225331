import $ from 'jquery';
let jQuery = $;
window.$ = $;
window.jQuery = $;
import _ from 'underscore';
window._ = _;
import 'bootstrap';
import { Datepicker } from 'vanillajs-datepicker';

import EvpForm from "./evpform";
import EvpAjaxCaller from "./evpajaxcaller";

$( document ).ready(function() {
    console.log('Document ready')

    $('.jsa-reload-page').on('click', function (e) {
        e.preventDefault();
        $('body').fadeOut(100);
        window.location.reload();
    })

    /*
    $('button[type=submit]').on('click', function (e) {
        const $this = $(e.target);
        $this.addClass('button-loading');
    })
    */

    // Datepicker
    const datepickers = [].slice.call(document.querySelectorAll('[data-datepicker]'))
    const datepickersList = datepickers.map(function (el) {
        return new Datepicker(el, {
            buttonClass: 'btn'
        });
    })

});

